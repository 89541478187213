export const findNearDivisor = (width: number, height: number) => {
  let targetDivisor = Math.floor(Math.max(width, height) * 0.02)

  const closeness = (divisor: number) => (width % divisor) + (height % divisor)

  let bestDivisor = targetDivisor
  let minCloseness = closeness(targetDivisor)

  for (
    let i = Math.floor(targetDivisor * 0.8);
    i < targetDivisor * 1.2;
    i += 0.2
  ) {
    if (i < 1) continue
    const currentCloseness = closeness(i)
    if (currentCloseness < minCloseness) {
      bestDivisor = i
      minCloseness = currentCloseness
    }
  }

  return bestDivisor
}

export const wgpuCreateBuffer = (
  device: any,
  arr: Float32Array | Uint16Array,
  usage: number,
  size?: number,
  label?: string
) => {
  let desc = {
    label,
    size: size ?? (arr.byteLength + 3) & ~3,
    usage,
    mappedAtCreation: true
  }
  let buffer = device.createBuffer(desc)
  const writeArray =
    arr instanceof Uint16Array
      ? new Uint16Array(buffer.getMappedRange())
      : new Float32Array(buffer.getMappedRange())
  writeArray.set(arr)
  buffer.unmap()
  return buffer
}
