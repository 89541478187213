// import tinycolor from 'tinycolor2'
import { wgsl } from '../../node_modules/wgsl-preprocessor/wgsl-preprocessor.js'
import { Sketch } from './Sketch'
import { wgpuCreateBuffer } from './utils.js'

export class Brot implements Sketch {
  private canvas: HTMLCanvasElement
  private ctx: GPUCanvasContext

  private adapter
  private device
  private encoder
  private pipeline
  private module
  private screenResBuffer
  private centerBuffer
  private zoomBuffer
  private dMouseBuffer
  private bindGroup
  private bindGroupLayout
  private setRenderBindGroup
  private colorTextureView

  private interestPoints = [
    { x: -0.52303406546462, y: 0.52633765087077 },
    { x: -1.74975308315148, y: 0.00004756553413 },
    { x: -0.74732528973769, y: -0.08281036681325 },
    { x: -0.41574765655524, y: -0.57454390394267 },
    { x: -0.1978078114302, y: 1.10035447603829 },
    { x: -0.52264842217641, y: 0.52684770280394 },
    { x: 0.33672387960403, y: 0.39284096510541 },
    { x: -1.74790595528095, y: 0.00194600423016 },
    { x: -1.74974771609071, y: 0.00005828594911 },
    { x: -0.52303294558693, y: 0.52633610977926 },
    { x: -0.76290645735314, y: 0.08273390579021 },
    { x: -0.777569, y: 0.136467 },
    { x: -0.76003482625226, y: 0.08052137845789 },
    { x: -0.03347354159771, y: -0.77327276891843 },
    { x: -0.101096, y: 0.956289 },
    { x: -0.745, y: 0.186 },
    { x: -0.10714993602959, y: 0.91210639328364 }
  ]

  zoom = 0.0001
  interestPointI = Math.floor(Math.random() * this.interestPoints.length)
  nextInterestPointI = (this.interestPointI + 1) % this.interestPoints.length
  center = this.interestPoints[this.interestPointI]

  startTime = performance.now()
  time = 0
  animationDuration = 60000

  lastMouseMoveTime = performance.now()
  lastMousePos: { x: null | number; y: null | number } = { x: null, y: null }
  dMouse = { x: 0, y: 0 }

  setup = async (canvas: HTMLCanvasElement): Promise<number> => {
    if (!navigator.gpu) {
      return -1
    }

    this.canvas = canvas
    this.ctx = this.canvas.getContext('webgpu')

    this.adapter = await navigator.gpu.requestAdapter()
    this.device = await this.adapter.requestDevice()
    // @ts-ignore
    const presentationFormat = navigator.gpu.getPreferredCanvasFormat()
    this.ctx.configure({
      device: this.device,
      format: presentationFormat
    })

    this.colorTextureView = this.ctx.getCurrentTexture().createView()

    this.setRenderBindGroup = () => {
      this.screenResBuffer = wgpuCreateBuffer(
        this.device,
        new Float32Array([this.canvas.width, this.canvas.height]),
        GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        undefined,
        'Screen Resolution Buffer'
      )

      this.zoomBuffer = wgpuCreateBuffer(
        this.device,
        new Float32Array([this.zoom]),
        GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        undefined,
        'Zoom Buffer'
      )

      this.centerBuffer = wgpuCreateBuffer(
        this.device,
        new Float32Array([this.center.x, this.center.y]),
        GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        undefined,
        'Center Pos Buffer'
      )

      this.dMouseBuffer = wgpuCreateBuffer(
        this.device,
        new Float32Array([this.dMouse.x, this.dMouse.y]),
        GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        undefined,
        'DMouse Buffer'
      )

      this.bindGroupLayout = this.device.createBindGroupLayout({
        entries: [
          {
            binding: 0,
            visibility: GPUShaderStage.FRAGMENT,
            buffer: { type: 'uniform' }
          },
          {
            binding: 1,
            visibility: GPUShaderStage.FRAGMENT,
            buffer: { type: 'uniform' }
          },
          {
            binding: 2,
            visibility: GPUShaderStage.FRAGMENT,
            buffer: { type: 'uniform' }
          },
          {
            binding: 3,
            visibility: GPUShaderStage.FRAGMENT,
            buffer: { type: 'uniform' }
          }
        ]
      })

      this.bindGroup = this.device.createBindGroup({
        layout: this.bindGroupLayout,
        entries: [
          {
            binding: 0,
            resource: { buffer: this.screenResBuffer }
          },
          {
            binding: 1,
            resource: { buffer: this.centerBuffer }
          },
          {
            binding: 2,
            resource: { buffer: this.zoomBuffer }
          },
          {
            binding: 3,
            resource: { buffer: this.dMouseBuffer }
          }
        ]
      })
    }

    this.module = this.device.createShaderModule({
      code: wgsl`
        @group(0) @binding(0) var<uniform> screenRes: vec2<f32>;
        @group(0) @binding(1) var<uniform> center: vec2<f32>;
        @group(0) @binding(2) var<uniform> zoom: f32;
        @group(0) @binding(3) var<uniform> dMouse: vec2<f32>;

        struct VertexOutput {
            @builtin(position) pos: vec4<f32>,
        };

        @vertex 
        fn vs(@builtin(vertex_index) vertex_index : u32) -> VertexOutput {
          let pos = array<vec2<f32>, 6>
            (
                vec2<f32>( 1.0,  1.0),
                vec2<f32>( 1.0, -1.0),
                vec2<f32>(-1.0, -1.0),

                vec2<f32>( 1.0,  1.0),
                vec2<f32>(-1.0, -1.0),
                vec2<f32>(-1.0,  1.0)
            );

          var out : VertexOutput;
          out.pos = vec4<f32>(pos[vertex_index], 0.0, 1.0);
          return out;
        }

        fn hue_to_rgb(h: f32) -> vec3<f32> {
            let x = 1.0 - abs(fract(h / 2.0) * 2.0 - 1.0);

            if (h < 1.0) {
                return vec3<f32>(1.0, x, 0.0);
            } else if (h < 2.0) {
                return vec3<f32>(x, 1.0, 0.0);
            } else if (h < 3.0) {
                return vec3<f32>(0.0, 1.0, x);
            } else if (h < 4.0) {
                return vec3<f32>(0.0, x, 1.0);
            } else if (h < 5.0) {
                return vec3<f32>(x, 0.0, 1.0);
            } else {
                return vec3<f32>(1.0, 0.0, x);
            }
        }

        @fragment
        fn fs(@builtin(position) coords: vec4<f32>) -> @location(0) vec4<f32> {
            // let scale = (coords.xy / screenRes.y -.5);

            let aspect_ratio = screenRes.x / screenRes.y;
            let scale = vec2<f32>(
              (coords.x / screenRes.x - 0.5) * aspect_ratio,
              (coords.y / screenRes.y - 0.5)
            ) * 2.0;  // Multiply by 2 to fill the screen

            let z: vec2<f32> = center + scale * zoom;

            var m : vec2<f32>;

            var i : f32;
            let max_i : f32 = 200;

            let s : f32 = 25.0;
            var color : vec4<f32> = vec4(0.);

            while i < max_i {
              m = vec2(pow(m.x, 2) - pow(m.y, 2), 2.0 * m.x * m.y) + z;

              if dot(m, m) > 4 {
                let hue = hue_to_rgb(((i / s) + 6 * (dMouse.x + dMouse.y)) % 6);
                let intensity = (hue.x + hue.y + hue.z) / 3;
                let drgb = intensity - hue;
                color = vec4(hue + drgb * (1 - pow((dMouse.x + dMouse.y) / 2, .4)), 1.0);
                break;
              }

              i += 1;
            }

            return color;
        }
    `
    })

    this.setRenderBindGroup()

    this.resize()

    window.addEventListener('resize', this.resize)

    document.addEventListener('mousemove', this.handleInteraction)
    document.addEventListener('touchmove', e => {
      e.preventDefault()
      this.handleInteraction(e.touches[0])
    })

    const pipelineLayoutDesc = {
      bindGroupLayouts: [this.bindGroupLayout]
    }

    const pipelineLayout = this.device.createPipelineLayout(pipelineLayoutDesc)

    const pipelineDesc = {
      layout: pipelineLayout,

      vertex: {
        entryPoint: 'vs',
        module: this.module
      },
      fragment: {
        entryPoint: 'fs',
        module: this.module,
        targets: [{ format: presentationFormat }]
      }
    }

    this.pipeline = await this.device.createRenderPipelineAsync(pipelineDesc)
    this.setRenderBindGroup()

    return 1
  }

  draw = (): void => {
    this.encoder = this.device.createCommandEncoder()

    const colorTexture = this.ctx.getCurrentTexture()
    this.colorTextureView = colorTexture.createView()

    this.time = performance.now() - this.startTime
    const t = this.time / this.animationDuration

    if (t < 1) {
      let f = 0.00005 + Math.abs(Math.sin(Math.PI * t) ** 4)
      this.zoom = 1 * f

      let g = 1 / (1 + (t / (1 - t)) ** -3)

      this.center = {
        x:
          this.interestPoints[this.interestPointI].x * (1 - g) +
          this.interestPoints[this.nextInterestPointI].x * g,
        y:
          this.interestPoints[this.interestPointI].y * (1 - g) +
          this.interestPoints[this.nextInterestPointI].y * g
      }
    } else {
      this.center = this.interestPoints[this.nextInterestPointI]

      this.interestPointI = this.nextInterestPointI

      while (true) {
        let nextP = Math.floor(Math.random() * this.interestPoints.length)
        if (nextP != this.nextInterestPointI) {
          this.nextInterestPointI = nextP
          break
        }
      }

      this.startTime = performance.now()
    }

    this.device.queue.writeBuffer(
      this.centerBuffer,
      0,
      new Float32Array([this.center.x, this.center.y])
    )
    this.device.queue.writeBuffer(
      this.zoomBuffer,
      0,
      new Float32Array([this.zoom])
    )

    this.dMouse.x = Math.max(
      0,
      this.dMouse.x - (performance.now() - this.lastMouseMoveTime) / 1e7
    )
    this.dMouse.y = Math.max(
      0,
      this.dMouse.y - (performance.now() - this.lastMouseMoveTime) / 1e7
    )

    this.device.queue.writeBuffer(
      this.dMouseBuffer,
      0,
      new Float32Array([this.dMouse.x, this.dMouse.y])
    )

    const pass = this.encoder.beginRenderPass({
      label: 'our basic canvas renderPass',
      colorAttachments: [
        {
          view: this.colorTextureView,
          clearValue: [0.0, 0.0, 0.0, 1],
          loadOp: 'clear',
          storeOp: 'store'
        }
      ]
    })

    pass.setPipeline(this.pipeline)
    pass.setBindGroup(0, this.bindGroup)
    pass.draw(6)
    pass.end()

    this.device.queue.submit([this.encoder.finish()])

    requestAnimationFrame(this.draw)
  }

  resize = (): void => {
    const dpr = window.devicePixelRatio || 1
    this.canvas.width = window.innerWidth * dpr
    this.canvas.height = window.innerHeight * dpr
    this.canvas.style.width = document.documentElement.clientWidth + 'px'
    this.canvas.style.height = document.documentElement.clientHeight + 'px'

    this.device.queue.writeBuffer(
      this.screenResBuffer,
      0,
      new Float32Array([this.canvas.width, this.canvas.height])
    )
  }

  handleInteraction = (event: MouseEvent | Touch): void => {
    const rect = this.canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    this.lastMouseMoveTime = performance.now()
    if (this.lastMousePos.x === null || this.lastMousePos.y === null) {
      this.lastMousePos = { x, y }
    } else {
      this.dMouse = {
        x: Math.min(1, Math.max(0, this.dMouse.x + 0.001)),
        y: Math.min(1, Math.max(0, this.dMouse.y + 0.001))
      }
      this.lastMousePos = { x, y }
    }
  }
}
