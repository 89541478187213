// https://www.youtube.com/watch?v=p7IGZTjC008
// https://doi.org/10.1109/MCG.2011.51

import { Sketch } from './Sketch'
import { findNearDivisor } from './utils'

type Vec2 = {
  x: number
  y: number
}

type Drop = {
  c: Vec2
  r: number
  vertices: Vec2[]
  color?: number
}

export class Drops implements Sketch {
  private canvas: HTMLCanvasElement
  private ctx: CanvasRenderingContext2D

  width: number
  height: number

  drops: Drop[] = []
  mouse?: Vec2 = undefined

  dotLayoutSize: number
  dotSize: number
  dotOffset: number

  setup = async (canvas: HTMLCanvasElement): Promise<number> => {
    this.canvas = canvas
    this.ctx = (ctx_opt => {
      if (ctx_opt === null) throw new Error('Context is null')
      else return ctx_opt
    })(this.canvas.getContext('2d'))

    this.resize()

    window.addEventListener('resize', this.resize)
    document.addEventListener('mousemove', this.handleInteraction)
    document.addEventListener('touchmove', e => {
      e.preventDefault()
      this.handleInteraction(e.touches[0])
    })

    return 1
  }

  draw = (): void => {
    this.ctx.fillStyle = 'black'
    this.ctx.fillRect(0, 0, this.width, this.height)

    this.addDrop()
    this.drawDrops()

    requestAnimationFrame(this.draw)
  }

  resize = (): void => {
    this.width = document.documentElement.clientWidth
    this.height = document.documentElement.clientHeight

    const dpr = window.devicePixelRatio || 1
    this.canvas.width = window.innerWidth * dpr
    this.canvas.height = window.innerHeight * dpr
    this.canvas.style.width = document.documentElement.clientWidth + 'px'
    this.canvas.style.height = document.documentElement.clientHeight + 'px'

    this.dotLayoutSize = findNearDivisor(this.width, this.height)
    this.dotSize = this.dotLayoutSize / 5
    this.dotOffset = (this.dotLayoutSize * 4) / 5

    this.ctx.scale(dpr, dpr)
  }

  private addDrop = (): void => {
    let r = this.dotSize // Math.random() * (this.height / 10) + 5

    let c: Vec2 = {
      x:
        Math.round((Math.random() * this.width) / this.dotOffset) *
        this.dotOffset,
      y:
        Math.round((Math.random() * this.height) / this.dotOffset) *
        this.dotOffset
    }

    let n_vertices = 200

    let vertices: Vec2[] = Array.from({ length: n_vertices }).map((_, i) => {
      let angle = (i / n_vertices) * (2 * Math.PI)
      return {
        x: r * Math.cos(angle) + c.x,
        y: r * Math.sin(angle) + c.y
      }
    })

    // marble old drops
    this.drops.forEach((drop, dropI) => {
      let dropIsIn = false
      drop.vertices = drop.vertices.map(v => {
        let m = Math.sqrt(Math.pow(v.x - c.x, 2) + Math.pow(v.y - c.y, 2))

        let x =
          c.x + (v.x - c.x) * Math.sqrt(1 + Math.pow(r, 2) / Math.pow(m, 2))
        let y =
          c.y + (v.y - c.y) * Math.sqrt(1 + Math.pow(r, 2) / Math.pow(m, 2))

        if (!dropIsIn && x > 0 && x < this.width && y > 0 && y < this.height)
          dropIsIn = true

        return {
          x,
          y
        }
      })

      if (!dropIsIn) {
        this.drops.splice(dropI, 1)
      }
    })

    this.drops.push({
      c,
      r,
      vertices,
      color: 255 // Math.random() * 255
    })

    for (let i = 0; i < this.drops.length; i++) {
      if (this.drops.at(i).color <= 5) {
        this.drops.splice(i, 1)
      } else {
        this.drops.at(i).color -= 0.3
      }
    }
  }

  private addTine = (p1: Vec2, p2: Vec2) => {
    if (p1.x == p2.x && p1.y == p2.y) return

    let line = { x: p2.x - p1.x, y: p2.y - p1.y }

    let line_mag = Math.sqrt(line.x * line.x + line.y * line.y)

    let m = { x: line.x / line_mag, y: line.y / line_mag }
    let n = { x: -m.y, y: m.x }

    let alpha = line_mag / 8
    let lambda = 100

    this.drops.forEach(drop => {
      drop.vertices = drop.vertices.map(v => {
        let pma = { x: v.x - p2.x, y: v.y - p2.y }
        let pmadotn = pma.x * n.x + pma.y + n.y
        let d = Math.abs(pmadotn)

        let stuff = (alpha * lambda) / (d + lambda)

        let p = {
          x: v.x + stuff * m.x,
          y: v.y + stuff * m.y
        }

        return {
          x: p.x,
          y: p.y
        }
      })
    })
  }

  private drawDrops = () => {
    for (let i = this.drops.length - 1; i >= 0; i--) {
      let drop = this.drops.at(i)
      if (!drop) continue

      // this.ctx.fillStyle = 'white'
      this.ctx.fillStyle = `rgb(${drop.color}, ${drop.color}, ${drop.color})`
      this.ctx.beginPath()
      this.ctx.moveTo(drop.vertices[0].x, drop.vertices[0].y)
      drop.vertices.forEach(v => {
        this.ctx.lineTo(v.x, v.y)
      })
      this.ctx.fill()
    }
  }

  private updateMousePosition = (event: MouseEvent | Touch) => {
    const rect = this.canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    let newMouse = { x, y }

    if (this.mouse !== undefined) {
      this.addTine(this.mouse, newMouse)
    }
    this.mouse = newMouse
  }

  private handleInteraction = (event: MouseEvent | Touch) => {
    this.updateMousePosition(event)
  }

  private normalDistribution = (mean: number, stdDev: number): number => {
    let u = 0,
      v = 0
    while (u === 0) u = Math.random()
    while (v === 0) v = Math.random()
    let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)
    return num * stdDev + mean
  }
}
